<template>
    <div class="floating-list-bubble">
        <!-- <div class="select-timelimit" v-if="this.bubbleType!=3">
            <el-dropdown trigger="click" @command="handleCommand">
                <span class="dropdown-title">
                    <font-icon class="talent-icon talent-icon-calendar" href="#icon-calendar"></font-icon>
                    {{timeLimit}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="timelimit-dropdown-menu float-list-timelimit-item">
                    <el-dropdown-item 
                        v-for="item in timeLimits" 
                        :key="item.name" 
                        :class="{'bubble-date-selected':timeLimit==item.name}" 
                        :command="item"
                    >{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div> -->
        <!-- 新的时间组件和人员选择 -->
        <div 
            class="timer-member-wrap" 
            v-if="([0, 1, 4].includes(this.bubbleType)) || ([3].includes(this.bubbleType) && isFinancial)"
        >
        <!-- <div 
            class="timer-member-wrap" 
            v-if="([0, 1, 4].includes(this.bubbleType))"
        > -->
            <div 
                v-if="![3].includes(this.bubbleType)"
                class="date-range"
                :class="{
                    'sm': [0,1].includes(this.bubbleType)
                }"
                >
                <el-date-picker
                    size="small"
                    align="left"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    :editable="false"
                    :clearable="false"
                    range-separator="-"
                    class="performance-date-input"
                    v-model="performanceDate"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="changePerformanceDate"
                    :picker-options="performanceDateOption"
                    popper-class="performance-date-range"
                ></el-date-picker>
                <div class="date-input-icon"></div>
            </div>
            <div class="employees" v-if="![0,1].includes(this.bubbleType)">
                <el-popover
                    popper-class="team-member-popover"
                    placement="bottom-end"
                    width="700"
                    trigger="click"
                    v-model="teamMemberPopoverVisible"
                    >
                    <team-member-select
                        ref="teamSelect"
                        :isAllMembers="true"
                        :isShowDimission="false"
                        :date="[3].includes(this.bubbleType) ? perDate : performanceDate"
                        :isHistoryData="true"
                        :visible="teamMemberPopoverVisible"
                        :selected="selectedUserIds"
                        :isFinancial="isFinancial"
                        @item-select-cb="employeeSelect"
                    ></team-member-select>
                    <div slot="reference" class="employee-select-text">
                        <i class="icon-font icon-user-select doll visible"></i>
                        <span class="text">{{selectedMembersText}}</span>
                        <i v-if="!teamMemberPopoverVisible" class="el-icon-caret-bottom"></i>
                        <i v-else class="el-icon-caret-top"></i>
                    </div>
                </el-popover>
            </div>
        </div>

        <div class="bubble-list" v-if="[0,1].includes(this.bubbleType)">
            <ul>
                <li 
                    v-for="item in bubbles.slice(0, 5)" 
                    :key="item.name" 
                    :class="[{'selected':currentBubble===item.status,'disabled':item.count==0},item.colorClass]" 
                    @click="handleBubble(item)"
                >{{item.name+" "+item.count}}</li>
            </ul>
            <!-- <el-dropdown>
                <span class="el-dropdown-link">
                    更多条件<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>黄金糕</el-dropdown-item>
                    <el-dropdown-item>狮子头</el-dropdown-item>
                    <el-dropdown-item>螺蛳粉</el-dropdown-item>
                    <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                    <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
                </el-dropdown-menu>
                title="标题"
                width="200"
            </el-dropdown> -->
            <el-popover
                placement="bottom"
                trigger="hover"
                width="550"
                popper-class="more-bubble"
                >
                <span class="el-dropdown-link" slot="reference" style="color: #38BC9D;font-weight: bold;">
                    更多条件<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <div class="bubbles-dialog">
                    <li 
                        v-for="item in bubbles.slice(5)" 
                        :key="item.name" 
                        :class="[{'selected':currentBubble===item.status,'disabled':item.count==0},item.colorClass]" 
                        @click="handleBubble(item)"
                    >{{item.name+" "+item.count}}</li>
                </div>
            </el-popover>
        </div>

        <div class="select-wrap" v-if="[3].includes(this.bubbleType) && !isFinancial">
            <el-select 
                class="fl-select" 
                clearable 
                v-model="selectData.PaymentMethodType" 
                @change="handleSelectChange" 
                placeholder="付款方式">
                <el-option label="全部" value="-1"></el-option>
                <el-option label="全款" value="0"></el-option>
                <el-option label="首款" value="1"></el-option>
                <el-option label="尾款" value="2"></el-option>
            </el-select>
            <el-select 
                class="fl-select" 
                clearable 
                v-model="selectData.invoiceStatus" 
                @change="handleSelectChange" 
                placeholder="发票状态">
                <el-option label="全部" value="-1"></el-option>
                <el-option label="待确认" value="0"></el-option>
                <el-option label="已确认待开票" value="1"></el-option>
                <el-option label="已驳回" value="3"></el-option>
                <el-option label="待回款" value="9"></el-option>
                <el-option label="已回款" value="13"></el-option>
                <el-option label="已取消" value="19"></el-option>
                <el-option label="已作废" value="29"></el-option>
            </el-select>
            <el-select 
                class="fl-select" 
                clearable
                v-model="selectData.invoiceType" 
                @change="handleSelectChange" 
                placeholder="发票类型">
                <el-option label="全部" value="-1"></el-option>
                <el-option label="正数发票" value="0"></el-option>
                <el-option label="负数发票" value="1"></el-option>
            </el-select>

            <el-select 
                class="fl-select" 
                clearable
                v-model="selectData.invoiceEntity" 
                @change="handleSelectChange" 
                v-if="isFinancial"
                placeholder="开票主体">
                <el-option label="全部" value="-1"></el-option>
                <el-option
                    v-for="(item, index) in invoiceEntityArray"
                    :key="'option_'+index"
                    :label="item"
                    :value="item"
                    ></el-option>
            </el-select>
        </div>

        <div class="select-wrap" v-if="[4].includes(this.bubbleType)">
            <el-input class="fl-select" 
                v-model.trim="candidateName" 
                placeholder="搜索候选人姓名"
                @change="handleCandidateNameChange"
                >
            </el-input>
        </div>
        
        <div class="btns-wrap" v-if="this.bubbleType==3 && isFinancial">
            <el-button 
                class="export-btn" 
                :loading="exportLoading" 
                @click="exportDetail">导出明细</el-button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import floatingListService from '#/js/service/floatingListService.js';
const YESTODAY = moment().subtract(1, 'days').format('YYYY-MM-DD');
const TODAY = moment().format('YYYY-MM-DD');
const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");
const dataRangeData = {
                startDate: lastMonth,
                endDate: TODAY
            };
export default {
    name: 'flosting-list-bubble',
    props:{
        perDate: {
            type: Array,
            default: function() {
                return []
            }
        },
        /**
         * bubbleType
         * 0:收到的推荐
         * 1:我的推荐
         * 2:未申请的开票
         * 3:我的开票
         **/
        bubbleType:{
            type:Number,
            default:1
        },

        /**
         * bubbleCount
         * name:bubble名称
         * count:bubble数量
         **/
        bubbleCount:{
            type:Array,
            default:[],
        },
        selectedUserIds: {
            type: Array,
            default: () => []
        },
        exportLoading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TeamMemberSelect,
    },
    watch: {
        perDate(val) {
            // console.log(val)
            // console.log([3].includes(this.bubbleType))
            this.$nextTick(() => {
                this.$refs.teamSelect && this.$refs.teamSelect.getHistoryTeamData(true);
            });
        }
    },
    computed:{
        bubbles(){
            // if(this.bubbleType == 3) {
            //     // 开票申请气泡
            //     this.applyBublesOrigin.forEach(item => {
            //         this.bubbleCount.forEach(countItem => {
            //             if(countItem.status == item.status){
            //                 item.count = countItem.count;
            //             }
            //         });
            //     });
            //     return this.applyBublesOrigin;
            // } else {
                let bubbleCount = this.bubbleCount, bubbles=this.bubblesOrigin;
                let _nOfferingCount = 0;
                bubbles.forEach(countItem => {
                    countItem.count = 0;
                    bubbleCount.forEach(item => {
                        if(countItem.status == item.status){
                            countItem.count = item.count;
                        }
                    });
                });
                
                // 将Offer中的6个状态统一成offer中状态
                bubbleCount.forEach(item => {
                    if([7, 9, 10, 11, 12, 13].includes(item.status)){
                        _nOfferingCount += +item.count;
                    }
                });
                bubbles.find(item => item.status == 7).count = _nOfferingCount;

                return bubbles.filter((item)=>{
                    return (item.name!=='未处理' && this.bubbleType==1) || (item.name !== '推荐待反馈' && this.bubbleType == 0);
                });
            // }
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        // 是PM还是财务人员
        isFinancial () {
            return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('Financial');
        },
    },
    data() {
        let _minTime = null,
            _maxTime = new Date().getTime();
        return {
            timeLimit:'最近一周',
            timeLimits:[
                {name:'全部',value:0},
                {name: '最近一周', value: 7},
                {name: '最近一个月', value: 30},
                {name: '最近三个月', value: 90},
                {name: '最近半年', value: 180},
                {name: '半年以上', value: 181}
            ],
            bubblesOrigin:[
                {status:0,name:'未处理',count:0,colorClass:'bubble-item bubble-color-a'},
                {status:0,name:'推荐待反馈',count:0,colorClass:'bubble-item bubble-color-a'},
                {status:1,name:'已接受待推进面试',count:0,colorClass:'bubble-item bubble-color-b'},
                {status:64,name:'面试待确认',count:0,colorClass:'bubble-item bubble-color-b'},
                {status:4,name:'面试待安排',count:0,colorClass:'bubble-item bubble-color-c'},
                {status:2,name:'推荐拒绝',count:0,colorClass:'bubble-item'},
                {status:5,name:'面试已安排',count:0,colorClass:'bubble-item'},
                // {status:7,name:'面试已反馈',count:0,colorClass:'bubble-item'},
                // {status:8,name:'offer中',count:0,colorClass:'bubble-item'},
                // {status:9,name:'已offer',count:0,colorClass:'bubble-item'},

                {status:407,name:'面试已反馈',count:0,colorClass:'bubble-item'},
                {status:7,name:'offering',count:0,colorClass:'bubble-item'},
                {status:8,name:'已offer',count:0,colorClass:'bubble-item'},

                {status:16,name:'入职',count:0,colorClass:'bubble-item'},
                {status:32,name:'离职',count:0,colorClass:'bubble-item'},
                {status:1024,name:'淘汰',count:0,colorClass:'bubble-item'},
                {status:24, name:'开票中', count:0, colorClass:'bubble-item'},
                {status:26, name:'已回款', count:0, colorClass:'bubble-item'},
            ],
            // 开票申请的泡泡
            // applyBublesOrigin: [
            //     { status: 101, name: '未开票', count: 0, colorClass:'bubble-item bubble-color-a' },
            //     { status: 102, name: '未回款', count: 0, colorClass:'bubble-item bubble-color-a' },
            //     { status: 103, name: '已回款', count: 0, colorClass:'bubble-item bubble-color-a' },
            //     { status: 104, name: '已驳回', count: 0, colorClass:'bubble-item bubble-color-a' },
            //     { status: 105, name: '取消申请', count: 0, colorClass:'bubble-item bubble-color-a' },
            //     { status: 106, name: '首款', count: 0, colorClass: 'bubble-item' },
            //     { status: 107, name: '全款', count: 0, colorClass: 'bubble-item' },
            // ],
            currentBubble:'',
            bubbleText:'',
            
            performanceDate: [dataRangeData.startDate || YESTODAY, dataRangeData.endDate || TODAY],
            performanceDateOption: {
                onPick: time => {
                    // 如果选择了只选择了一个时间
                    if (!time.maxDate) {
                        let _nDays = this.key == 'trackingListDetails' ? 31: 365;
                        let timeRange = _nDays*24*60*60*1000, // *天
                            _dNow = new Date().getTime();

                        _minTime = time.minDate.getTime() - timeRange; // 最小时间
                        _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                        _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                    // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                    } else {
                        _minTime = _maxTime = null;
                    }
                },
                disabledDate: time => {
                    // onPick后触发
                    // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
                    if(_minTime && _maxTime){
                        return time.getTime() < _minTime || time.getTime() > _maxTime;
                    }
                    if(!_minTime && _maxTime) {
                        return time.getTime() > _maxTime;
                    }
                },
                // disabledDate(date) {
                //     return date.getTime() > Date.now();
                // },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '今年',
                    onClick(picker) {
                        const start = moment().format('YYYY') + '-01-01';
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近半年',
                    onClick(picker) {
                        const start = moment().subtract(6, 'months').format('YYYY-MM-DD');
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一年',
                    onClick(picker) {
                        const start = moment().subtract(1, 'years').format('YYYY-MM-DD');
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }
                ]
            },

            teamMemberPopoverVisible: false,
            selectedMembersText: '全部成员',
            // selectedUserIds: [],

            selectData: {
                PaymentMethodType: '',
                invoiceStatus: '',
                invoiceType: '',
                invoiceEntity: '',
            },
            candidateName: '',
            invoiceEntityArray: [],
        };
    },
    methods:{
        handleCommand(item){
            this.timeLimit = item.name;
            let today = new Date();
            let endDate ='',startDate='';
            let tableName = this.bubbleType == 0 ? 'RecommendToMe' : 'MyRecommend';
            switch(item.value){
                case 0:
                    endDate = '';
                    startDate = '';
                    break;
                case 181:
                    endDate = new Date(today.setTime(today.getTime() - (item.value-1) * 24 * 60 * 60 * 1000));
                    endDate = moment(endDate).format('YYYY-MM-DD');
                    startDate = "1970-01-01"
                    break;
                default:
                    endDate = moment(today).format('YYYY-MM-DD');
                    startDate = new Date(today.setTime(today.getTime() - (item.value-1) * 24 * 60 * 60 * 1000));
                    startDate = moment(startDate).format('YYYY-MM-DD');
            }
            this.$emit('timeLimit-change', {
                    time: { startDate: startDate, endDate: endDate },
                    bubble: { name: this.bubbleText, status: this.currentBubble }
                    });
                    
            this.performanceDate = [startDate, endDate];

            _tracker.track("Filter", JSON.stringify({
                "tableName": tableName,
                "type": "Date",
                "text": this.timeLimit
            }))
        },

        handleBubble(item){
            let tableName = this.bubbleType == 0 ? 'RecommendToMe' : 'MyRecommend';
            if(item.count<=0){
                return;
            }
            if(this.currentBubble === item.status){
                this.currentBubble ='';
                this.$emit('bubble-clear')
                return
            }
            this.currentBubble =item.status;
            this.bubbleText = item.name;
            this.$emit('bubble-click',{status:item.status,name:item.name});

            _tracker.track("Filter", JSON.stringify({
                "tableName": tableName,
                "type": "Bubble",
                "text": item.name
            }))
        },

        clearTime(){
            this.timeLimit = "近1个月";
        },

        clearBubble(){
            this.currentBubble = '';
        },
        setBubble (type, status){
            this.currentBubble = +status;
            this.timeLimit = type;
            switch(type){
                case '全部': this.$emit('timeLimit-change', { startDate: '', endDate: ''} ); break;
                case '近1个月': 
                    let today = new Date();
                    let endDate ='',startDate='';
                    startDate = new Date(today.setTime(today.getTime() - 31 * 24 * 60 * 60 * 1000));
                    startDate = moment(startDate).format('YYYY-MM-DD');
                    endDate = moment(today).format('YYYY-MM-DD');
                    this.$emit('timeLimit-change', { startDate: startDate, endDate: endDate });
                    break;
            }
            for(let key in this.bubblesOrigin){
                if(this.bubblesOrigin[key].status == status){
                    this.$emit('bubble-click',{
                        status: this.bubblesOrigin[key].status,
                        name: this.bubbleType === 0 ? '未处理' : '推荐待反馈'
                    });
                }
            }
        },
        changePerformanceDate() {
            if((this.bubbleType == 3 && this.isFinancial) || this.bubbleType == 4) {
                this.$emit('timeLimit-change', {
                    time: { 
                        startDate: moment(this.performanceDate[0]).format('YYYY-MM-DD'), 
                        endDate: moment(this.performanceDate[1]).format('YYYY-MM-DD'),
                        },
                    bubble: { name: this.bubbleText, status: this.currentBubble }
                    }, false);
                this.$nextTick(() => {
                    this.$refs.teamSelect && this.$refs.teamSelect.getHistoryTeamData(true);
                });
            } else {
                this.$emit('timeLimit-change', {
                    time: { 
                        startDate: moment(this.performanceDate[0]).format('YYYY-MM-DD'), 
                        endDate: moment(this.performanceDate[1]).format('YYYY-MM-DD'),
                        },
                    bubble: { name: this.bubbleText, status: this.currentBubble }
                    });
            }
        },
        employeeSelect(userIds, userText) {
            this.selectedMembersText = userText;
            this.$emit('member-change', userIds);
            this.teamMemberPopoverVisible = false;
        },
        exportDetail() {
            this.$emit('export-detail');
        },
        handleSelectChange() {
            this.$emit('select-change', {
                time: { 
                    startDate: moment(this.performanceDate[0]).format('YYYY-MM-DD'), 
                    endDate: moment(this.performanceDate[1]).format('YYYY-MM-DD'),
                    },
                selectData: this.selectData,
                });
        },
        handleCandidateNameChange(name) {
            this.$emit('candidata-search', name);
        },
        getInvoiceEntity() {
            floatingListService.getInvoiceEntity().then(res => {
                this.invoiceEntityArray = res;
            }).catch(err => {
                console.log(err);
            });
        },
    },
    mounted() {
        this.$refs.teamSelect && this.$refs.teamSelect.getHistoryTeamData(true);
        if(this.isFinancial) {
            this.getInvoiceEntity();
        }
    },
}
</script>
<style lang="scss">

.floating-list-bubble{
    // padding: 10px 20px;
    // background-color: #FFFFFF;
    // border-radius: 4px;
    letter-spacing: 0px;
    // position: relative;
    user-select: none;
    position: relative;
    .select-timelimit{
        // position: absolute;
        display: inline-block;
        .el-dropdown{
            cursor: pointer;
            font-size: 14px;
            color: #666666;
            background: #FFFFFF;
            border: 1px solid #38BC9D;
            border-radius: 4px;
            .dropdown-title{
                outline: none;
                width: 125px;
                height: 26px;
                line-height: 26px;
                padding: 0 25px 0 10px;
                display: inline-block;
                text-align: right;
                .icon-calendar{
                    margin-right: 9px;
                }
                .talent-icon-calendar{
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 10px;
                    top: 46%;
                    transform: translateY(-50%);
                }
                .el-icon--right{
                    position: absolute;
                    right: 10px;
                    top: 6px;
                }
            }
        }
    }
    .timer-member-wrap{
        display: inline-block;
        margin-right: 15px;
        vertical-align: top;
        .sm{
            line-height: 29px!important;
            .performance-date-input.el-date-editor {
                height: 28px!important;
                .el-range__icon {
                    line-height: 22px!important;
                }
                .el-range-separator{
                    line-height: 20px!important;
                }
            }
        }
        .date-range {
            margin-right: 30px;
            line-height: 42px;
            position: relative;
            display: inline-block;
            vertical-align: top;
            .performance-date-input.el-date-editor {
                width: auto;
                height: 41px;
                border: 1px solid #d3d3d3!important;
                .el-range__icon {
                    line-height: 34px;
                }
                input.el-range-input {
                    width: 90px;
                    height: auto;
                    padding: 0;
                }
                & + .date-input-icon {
                    content: '';
                    top: 50%;
                    display: block;
                    position: absolute;
                    right: 16px;
                    transform: translateY(-50%);
                    border-top: 6px solid #a3afb7;
                    border-bottom: none;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                }
                &.is-active {
                    & + .date-input-icon {
                        border-top: none;
                        border-bottom: 6px solid #a3afb7;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                    }
                }
                .el-range-separator{
                    line-height: 30px;
                }
            }
        }
        .employees {
            position: relative;
            display: inline-block;
            // margin-left: 30px;
            width: 190px;
            height: 42px;
            line-height: 42px;
            padding: 0 12px;
            border: 1px solid #d3d3d3;
            border-radius: 4px;

            .employee-select-text {
                display: flex;
                align-items: center;

                .text {
                    flex: 1;
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .employee-select-arrow {
                margin-right: 0;
            }
        }
    }
    .team-member-popover {
        transform: translateX(380px);
    }
    @media screen and (max-width:1536px) {
        .bubble-list{
            max-width: calc(100% - 300px);
        }
    }
    .bubble-list{
        // padding-left: 135px;
        display: inline-block;
        >ul{
            display: inline-block;
            margin-right: 5px;
        }
        .bubble-item{
            display: inline-block;
            height: 28px;
            line-height: 28px;
            border-radius: 14px;
            font-size: 14px;
            color: #666666;
            text-align: center;
            background-color: rgba(56,188,157,0.1);
            margin-left: 10px;
            padding: 0px 10px;
            cursor: pointer;
            &:hover,&.selected{
                background-color: rgba(56,188,157,1);
                color: #fff;
            }
            &.disabled{
                cursor: not-allowed;
                &:hover{
                    color: #666666;
                    background-color: rgba(56,188,157,0.1);
                }
            }
        }

        .bubble-color-a{
            background-color: rgba(252,120,89,0.1);
            &:hover,&.selected{
                background-color: rgba(252,120,89,1);
            }
            &.disabled{
                &:hover{
                    background-color: rgba(252,120,89,0.1);
                }
            }
        }
        .bubble-color-b{
            background-color: rgba(74,144,226,0.1);
            &:hover,&.selected{
                background-color: rgba(74,144,226,1);
            }
            &.disabled{
                &:hover{
                    background-color: rgba(74,144,226,0.1);
                }
            }

        }
        .bubble-color-c{
            background-color: rgba(245,166,35,0.1);
            &:hover,&.selected{
                background-color: rgba(245,166,35,1);
            }
            &.disabled{
                &:hover{
                    background-color: rgba(245,166,35,0.1);
                }
            }
        }
    }

    .select-wrap{
        display: inline-block;
        .fl-select{
            border: 1px solid #D3D3D3;
            border-radius: 4px;
            color: #999;
            margin-left: 30px;
            width: 150px;
            &:first-child{
                margin-left: 10px;
            }
        }
    }
    .btns-wrap{
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 0;
        .export-btn{
            // width: 81px;
            // height: 28px;
            // line-height: 25px;
            border: 1px solid #38BB9C;
            border-radius: 4px;
            background: white;
            color: #38BB9C;
            // cursor: pointer;
            &:hover{
                background: #fafafa;
            }
        }
    }
}


.timelimit-dropdown-menu.float-list-timelimit-item{
    padding: 0;
    user-select: none;
    .el-dropdown-menu__item.bubble-date-selected{
        background-color: rgba(56,188,157,0.1);
        color: $primary;
    }
    .popper__arrow {
        display: none;
    }
}
@media screen and (max-width: 1366px){
    .floating-list-bubble{
        display: block;
        .select-timelimit{
            position: absolute;
            .el-dropdown{
                .dropdown-title{
                    font-size: 12px;
                    width: 105px;
                    height: 26px;
                    line-height: 26px;
                    padding-right: 10px;
                    .icon-calendar{
                        font-size: 12px;
                        margin-right: 4px;
                    }
                    .el-icon--right{
                        display: none;
                    }
                }
            }
        }
        .bubble-list{
            // padding-left: 105px;
            // min-width: 1023px;
            .bubble-item{
                font-size: 12px;
                height: 28px;
                line-height: 28px;
                margin-left: 10px;
                padding: 0px 10px;
            }
        }
    }
    .timelimit-dropdown-menu.float-list-timelimit-item{
        width: 105px;
        .el-dropdown-menu__item{
            padding: 0 10px;
        }
    }
}
.more-bubble{
    .bubbles-dialog{
        .bubble-item{
            display: inline-block;
            margin-bottom: 4px;
            height: 28px;
            line-height: 28px;
            border-radius: 14px;
            font-size: 14px;
            color: #666666;
            text-align: center;
            background-color: rgba(56,188,157,0.1);
            margin-left: 10px;
            padding: 0px 10px;
            cursor: pointer;
            &:hover,&.selected{
                background-color: rgba(56,188,157,1);
                color: #fff;
            }
            &.disabled{
                cursor: not-allowed;
                &:hover{
                    color: #666666;
                    background-color: rgba(56,188,157,0.1);
                }
            }
        }

        .bubble-color-a{
            background-color: rgba(252,120,89,0.1);
            &:hover,&.selected{
                background-color: rgba(252,120,89,1);
            }
            &.disabled{
                &:hover{
                    background-color: rgba(252,120,89,0.1);
                }
            }
        }
        .bubble-color-b{
            background-color: rgba(74,144,226,0.1);
            &:hover,&.selected{
                background-color: rgba(74,144,226,1);
            }
            &.disabled{
                &:hover{
                    background-color: rgba(74,144,226,0.1);
                }
            }
        }
        .bubble-color-c{
            background-color: rgba(245,166,35,0.1);
            &:hover,&.selected{
                background-color: rgba(245,166,35,1);
            }
            &.disabled{
                &:hover{
                    background-color: rgba(245,166,35,0.1);
                }
            }
        }
    }
}
</style>
