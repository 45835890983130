var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "floating-list-bubble" }, [
    [0, 1, 4].includes(this.bubbleType) ||
    ([3].includes(this.bubbleType) && _vm.isFinancial)
      ? _c("div", { staticClass: "timer-member-wrap" }, [
          ![3].includes(this.bubbleType)
            ? _c(
                "div",
                {
                  staticClass: "date-range",
                  class: {
                    sm: [0, 1].includes(this.bubbleType),
                  },
                },
                [
                  _c("el-date-picker", {
                    staticClass: "performance-date-input",
                    attrs: {
                      size: "small",
                      align: "left",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                      editable: false,
                      clearable: false,
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.performanceDateOption,
                      "popper-class": "performance-date-range",
                    },
                    on: { change: _vm.changePerformanceDate },
                    model: {
                      value: _vm.performanceDate,
                      callback: function ($$v) {
                        _vm.performanceDate = $$v
                      },
                      expression: "performanceDate",
                    },
                  }),
                  _c("div", { staticClass: "date-input-icon" }),
                ],
                1
              )
            : _vm._e(),
          ![0, 1].includes(this.bubbleType)
            ? _c(
                "div",
                { staticClass: "employees" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "team-member-popover",
                        placement: "bottom-end",
                        width: "700",
                        trigger: "click",
                      },
                      model: {
                        value: _vm.teamMemberPopoverVisible,
                        callback: function ($$v) {
                          _vm.teamMemberPopoverVisible = $$v
                        },
                        expression: "teamMemberPopoverVisible",
                      },
                    },
                    [
                      _c("team-member-select", {
                        ref: "teamSelect",
                        attrs: {
                          isAllMembers: true,
                          isShowDimission: false,
                          date: [3].includes(this.bubbleType)
                            ? _vm.perDate
                            : _vm.performanceDate,
                          isHistoryData: true,
                          visible: _vm.teamMemberPopoverVisible,
                          selected: _vm.selectedUserIds,
                          isFinancial: _vm.isFinancial,
                        },
                        on: { "item-select-cb": _vm.employeeSelect },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "employee-select-text",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("i", {
                            staticClass:
                              "icon-font icon-user-select doll visible",
                          }),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.selectedMembersText)),
                          ]),
                          !_vm.teamMemberPopoverVisible
                            ? _c("i", { staticClass: "el-icon-caret-bottom" })
                            : _c("i", { staticClass: "el-icon-caret-top" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    [0, 1].includes(this.bubbleType)
      ? _c(
          "div",
          { staticClass: "bubble-list" },
          [
            _c(
              "ul",
              _vm._l(_vm.bubbles.slice(0, 5), function (item) {
                return _c(
                  "li",
                  {
                    key: item.name,
                    class: [
                      {
                        selected: _vm.currentBubble === item.status,
                        disabled: item.count == 0,
                      },
                      item.colorClass,
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.handleBubble(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name + " " + item.count))]
                )
              }),
              0
            ),
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "bottom",
                  trigger: "hover",
                  width: "550",
                  "popper-class": "more-bubble",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "el-dropdown-link",
                    staticStyle: { color: "#38BC9D", "font-weight": "bold" },
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _vm._v("\n                更多条件"),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "bubbles-dialog" },
                  _vm._l(_vm.bubbles.slice(5), function (item) {
                    return _c(
                      "li",
                      {
                        key: item.name,
                        class: [
                          {
                            selected: _vm.currentBubble === item.status,
                            disabled: item.count == 0,
                          },
                          item.colorClass,
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.handleBubble(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name + " " + item.count))]
                    )
                  }),
                  0
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    [3].includes(this.bubbleType) && !_vm.isFinancial
      ? _c(
          "div",
          { staticClass: "select-wrap" },
          [
            _c(
              "el-select",
              {
                staticClass: "fl-select",
                attrs: { clearable: "", placeholder: "付款方式" },
                on: { change: _vm.handleSelectChange },
                model: {
                  value: _vm.selectData.PaymentMethodType,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectData, "PaymentMethodType", $$v)
                  },
                  expression: "selectData.PaymentMethodType",
                },
              },
              [
                _c("el-option", { attrs: { label: "全部", value: "-1" } }),
                _c("el-option", { attrs: { label: "全款", value: "0" } }),
                _c("el-option", { attrs: { label: "首款", value: "1" } }),
                _c("el-option", { attrs: { label: "尾款", value: "2" } }),
              ],
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "fl-select",
                attrs: { clearable: "", placeholder: "发票状态" },
                on: { change: _vm.handleSelectChange },
                model: {
                  value: _vm.selectData.invoiceStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectData, "invoiceStatus", $$v)
                  },
                  expression: "selectData.invoiceStatus",
                },
              },
              [
                _c("el-option", { attrs: { label: "全部", value: "-1" } }),
                _c("el-option", { attrs: { label: "待确认", value: "0" } }),
                _c("el-option", {
                  attrs: { label: "已确认待开票", value: "1" },
                }),
                _c("el-option", { attrs: { label: "已驳回", value: "3" } }),
                _c("el-option", { attrs: { label: "待回款", value: "9" } }),
                _c("el-option", { attrs: { label: "已回款", value: "13" } }),
                _c("el-option", { attrs: { label: "已取消", value: "19" } }),
                _c("el-option", { attrs: { label: "已作废", value: "29" } }),
              ],
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "fl-select",
                attrs: { clearable: "", placeholder: "发票类型" },
                on: { change: _vm.handleSelectChange },
                model: {
                  value: _vm.selectData.invoiceType,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectData, "invoiceType", $$v)
                  },
                  expression: "selectData.invoiceType",
                },
              },
              [
                _c("el-option", { attrs: { label: "全部", value: "-1" } }),
                _c("el-option", { attrs: { label: "正数发票", value: "0" } }),
                _c("el-option", { attrs: { label: "负数发票", value: "1" } }),
              ],
              1
            ),
            _vm.isFinancial
              ? _c(
                  "el-select",
                  {
                    staticClass: "fl-select",
                    attrs: { clearable: "", placeholder: "开票主体" },
                    on: { change: _vm.handleSelectChange },
                    model: {
                      value: _vm.selectData.invoiceEntity,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectData, "invoiceEntity", $$v)
                      },
                      expression: "selectData.invoiceEntity",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "全部", value: "-1" } }),
                    _vm._l(_vm.invoiceEntityArray, function (item, index) {
                      return _c("el-option", {
                        key: "option_" + index,
                        attrs: { label: item, value: item },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    [4].includes(this.bubbleType)
      ? _c(
          "div",
          { staticClass: "select-wrap" },
          [
            _c("el-input", {
              staticClass: "fl-select",
              attrs: { placeholder: "搜索候选人姓名" },
              on: { change: _vm.handleCandidateNameChange },
              model: {
                value: _vm.candidateName,
                callback: function ($$v) {
                  _vm.candidateName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "candidateName",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    this.bubbleType == 3 && _vm.isFinancial
      ? _c(
          "div",
          { staticClass: "btns-wrap" },
          [
            _c(
              "el-button",
              {
                staticClass: "export-btn",
                attrs: { loading: _vm.exportLoading },
                on: { click: _vm.exportDetail },
              },
              [_vm._v("导出明细")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }