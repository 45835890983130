export default {
    FullPaymentApplicationCreated: "全款待确认",
    FullPaymentInvoiceCreated: "全款待回款",
    FullPaymentApplicationCancel: "全款已取消",
    FullPaymentRejected: "全款已驳回",
    FullPaymentFundReceived: "全款已回款",
    FullPaymentApplicationConfirmed: "全款已确认待开票",
    FullPaymentVoided: "全款已作废",
    HeaderApplicationCreated: "首待确认，尾未申请",
    HeaderApplicationCreatedBalanceApplicationCreated: "首待确认，尾待确认",
    HeaderApplicationCreatedBalanceInvoiceCreated: "首待确认，尾待回款",
    HeaderApplicationCreatedBalanceApplicationCancel: "首待确认，尾已取消",
    HeaderApplicationCreatedBalanceRejected: "首待确认，尾已驳回",
    HeaderApplicationCreatedBalanceFundReceived: "首待确认，尾已回款",
    HeaderApplicationCreatedBalanceApplicationConfirmed: "首待确认，尾已确认待开票",
    HeaderApplicationCreatedBalanceVoided: "首待确认，尾已作废",
    HeaderApplicationConfirmed: "首已确认待开票，尾未申请",
    HeaderApplicationConfirmedBalanceApplicationCreated: "首已确认待开票，尾待确认",
    HeaderApplicationConfirmedBalanceInvoiceCreated: "首已确认待开票，尾待回款",
    HeaderApplicationConfirmedBalanceApplicationCancel: "首已确认待开票，尾已取消",
    HeaderApplicationConfirmedBalanceRejected: "首已确认待开票，尾已驳回",
    HeaderApplicationConfirmedBalanceFundReceived: "首已确认待开票，尾已回款",
    HeaderApplicationConfirmedBalanceApplicationConfirmed: "首已确认待开票，尾已确认待开票",
    HeaderApplicationConfirmedBalanceVoided: "首已确认待开票，尾已作废",
    HeaderInvoiceCreated: "首待回款，尾未申请",
    HeaderInvoiceCreatedBalanceApplicationCreated: "首待回款，尾待确认",
    HeaderInvoiceCreatedBalanceInvoiceCreated: "首待回款，尾待回款",
    HeaderInvoiceCreatedBalanceApplicationCancel: "首待回款，尾已取消",
    HeaderInvoiceCreatedBalanceRejected: "首待回款，尾已驳回",
    HeaderInvoiceCreatedBalanceFundReceived: "首待回款，尾已回款",
    HeaderInvoiceCreatedBalanceApplicationConfirmed: "首待回款，尾已确认待开票",
    HeaderInvoiceCreatedBalanceVoided: "首待回款，尾已作废",
    HeaderFundReceived: "首已回款，尾未申请",
    HeaderFundReceivedBalanceApplicationCreated: "首已回款，尾待确认",
    HeaderFundReceivedBalanceRejected: "首已回款，尾已驳回",
    HeaderFundReceivedBalanceApplicationCancel: "首已回款，尾已取消",
    HeaderFundReceivedBalanceInvoiceCreated: "首已回款，尾待回款",
    HeaderFundReceivedBalanceFundReceived: "首已回款，尾已回款",
    HeaderFundReceivedBalanceApplicationConfirmed: "首已回款，尾已确认待开票",
    HeaderFundReceivedBalanceVoided: "首已回款，尾已作废",
    HeaderApplicationCancel: "首已取消，尾未申请",
    HeaderApplicationCancelBalanceFundReceived: "首已取消，尾已回款",
    HeaderApplicationCancelBalanceApplicationCancel: "首已取消，尾已取消",
    HeaderApplicationCancelBalanceApplicationCreated: "首已取消，尾待确认",
    HeaderApplicationCancelBalanceInvoiceCreated: "首已取消，尾待回款",
    HeaderApplicationCancelBalanceRejected: "首已取消，尾已驳回",
    HeaderApplicationCancelBalanceApplicationConfirmed: "首已取消，尾已确认待开票",
    HeaderApplicationCancelBalanceVoided: "首已取消，尾已作废",
    HeaderPaymentRejected: "首已驳回，尾未申请",
    HeaderPaymentRejectedBalanceFundReceived: "首已驳回，尾已回款",
    HeaderPaymentRejectedBalanceApplicationCancel: "首已驳回，尾已取消",
    HeaderPaymentRejectedBalanceApplicationCreated: "首已驳回，尾待确认",
    HeaderPaymentRejectedBalanceInvoiceCreated: "首已驳回，尾待回款",
    HeaderPaymentRejectedBalanceRejected: "首已驳回，尾已驳回",
    HeaderPaymentRejectedBalanceApplicationConfirmed: "首已驳回，尾已确认待开票",
    HeaderPaymentRejectedBalanceVoided: "首已驳回，尾已作废",
    HeaderVoided: "首已作废，尾未申请",
    HeaderVoidedBalanceFundReceived: "首已作废，尾已回款",
    HeaderVoidedBalanceApplicationCancel: "首已作废，尾已取消",
    HeaderVoidedBalanceApplicationCreated: "首已作废，尾待确认",
    HeaderVoidedBalanceInvoiceCreated: "首已作废，尾待回款",
    HeaderVoidedBalanceRejected: "首已作废，尾已驳回",
    HeaderVoidedBalanceApplicationConfirmed: "首已作废，尾已确认待开票",
    HeaderVoidedBalanceVoided: "首已作废，尾已作废",

};