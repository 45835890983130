<template>
    <div class="business-classfication-dialog">
        <el-dialog
            title="编辑业务分类"
            :visible.sync="dialogVisible"
            width="500px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="业务分类" prop="toId">
                    <el-select v-model="ruleForm.BusinessClassificationId" placeholder="请选择目标业务分类">
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="onsubmit" :loading="submitLoading">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import businessClassificationService from '#/js/service/businessClassificationService.js';

export default {
    props: {

    },

    components: {

    },

    data() {
        return {
            dialogVisible: false,
            submitLoading: false,
            ruleForm: {
                RecommendationId: '',
                BusinessClassificationId: ''
            },
            options: [],
            rules: {
                BusinessClassificationId: [
                    { required: true, message: '请选择目标业务分类', trigger: 'change' },
                ],
            },
        };
    },

    mounted() {
        
    },

    methods: {
        show(item) {
            // console.log(item);
            this.ruleForm.RecommendationId = item.id;
            this.ruleForm.BusinessClassificationId = item.businessClassificationId;
            this.getOptionsList();
            this.dialogVisible = true;
        },
         // 获取业务分类列表
        getOptionsList() {
            let params = {
                start: 0,
                take: 100
            }
            businessClassificationService
                .getBusinessClassificationList(params).then(res => {
                    // console.log(res);
                    this.options = res.list;
                }).catch(err => {
                    console.log(err);
                })
        },
        resetForm() {
            this.$refs['ruleForm'].resetFields();
            this.ruleForm = {
                RecommendationId: '',
                BusinessClassificationId: ''
            };
        },
        handleClose() {
            this.resetForm();
            this.dialogVisible = false;
        },
        onsubmit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.submitLoading = true;
                    let params = Object.assign({}, this.ruleForm);
                    businessClassificationService
                        .updateBusinessClassification(params).then(res => {
                            // console.log(res)
                            shortTips('操作成功');
                            this.submitLoading = false;
                            this.dialogVisible = false;
                            this.resetForm();
                            this.$emit('refresh')
                        }).catch(err => {
                            console.log(err);
                            this.submitLoading = false;
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.business-classfication-dialog {
    .el-form {
        .el-select {
            width: 100%;
        }
    }
}
</style>